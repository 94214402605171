<template>
    <div class="view pa24">
        <el-form :model="ruleForm" label-width="auto">

            <el-form-item :label="type == 1 ? '商城版块' : type == 2 ? '预约版块' : '动态版块'">
                <el-upload :action="uploadUrl" :class="{ disabled: uploadDisabled }" list-type="picture-card" multiple
                    :on-progress="handProgress" :on-success="handleSuccess" :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove" :on-error="() => { isLoading = false }" :file-list="ruleForm.fileList"
                    :limit="6">
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">
                        上传图片的最佳尺寸：750像素*280像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M，最少上传1张最多可上传6张
                    </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :destroy-on-close="true">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveForm('0')">保存</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { insertCompanyPhoto, selectCompanyPhoto } from "@/api/client";
export default {
    data() {
        return {
            ruleForm: {
                fileList: [],
            },
            dialogVisible: false,
            dialogImageUrl: '',
            uploadUrl: this.$store.state.uploadingUrl,
            ossUrl: this.$store.state.ossUrl,
            type: 1,
            isLoading: false,
        };
    },
    computed: {
        uploadDisabled() {
            return this.ruleForm.fileList.length >= 6;

        },
    },
    created() {
        this.$set(this, 'type', this.$route.query.type)
        this.getCompanyPhoto()
    },

    methods: {
        handleRemove(file, fileList) {
            this.ruleForm.fileList = fileList
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        handProgress(event, file, fileList) {
            if (this.isLoading) {
                return
            }
            this.isLoading = true
            this.$message({
                message: "正在上传",
            });
        },
        handleSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.isLoading = false
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.fileList = fileList;
        },
        /**@method 发布 */
        saveForm() {
            let photos = this.ruleForm.fileList.map((item) =>
                item.response.data.indexOf("https") >= 0
                    ? item.response.data
                    : this.ossUrl + item.response.data + '.primary.png'
            )
            let data = {
                type: this.type,
                photoList: photos
            }
            insertCompanyPhoto(data)
                .then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: "设置成功",
                            type: "success",
                        });
                        this.$router.go(-1)
                    }
                })

        },
        getCompanyPhoto() {
            let data = {
                type: this.type,
            }
            selectCompanyPhoto(data)
                .then(res => {
                    let img = res.data.map(val => val.photo) || [];
                    this.ruleForm = {
                        fileList: img.map((item, i) => ({
                            url: item,
                            uid: i + 1,
                            response: {
                                data: item,
                            },
                        }))
                    }
                }).catch(err => {
                    this.ruleForm.fileList = [];
                })
        }
    },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
</style>
